import React from "react";
import Main from "../Main/Main";
import Navbar from "../Navbar/Navbar";

const Header = () => {
  return (
    <div>
      <Main />
    </div>
  );
};

export default Header;
